
.contact-title {
    font-size: 65px;
    font-weight: 400;
}

.contact-detail,
.contact-detail-email {
    margin-top: 20px;
    font-size: 40px;
    font-weight: 400;
    color: #868e96;
    text-decoration: none;
}
.contact-detail{
    font-size: 30px;
    font-weight: 700;
    color: black;
}
.contact-detail-email{
    font-size: 25px;
    font-weight: 400;
    padding: 15px;
}

.contact-detail:hover,
.contact-detail-email:hover {
    color: black;
    text-shadow: 2px 1px 2px #b5b5b5;
    transition: all 0.3s;
}

.contact-subtitle {
    text-transform: uppercase;
}

.contact-text-div {
    margin-top: 1.5rem;
}

.contact-margin-top {
    margin-top: 4rem;
}

.contact-image-div > img {
    width: 400px;
    height: 400px;
    border: .5rem solid #6c63ff;
    border-radius: 50%;
    margin-left: 25rem;
    margin-top: 2rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
    .contact-title {
        font-size: 56px;
        font-weight: 400;
        text-align: center;
    }

    .contact-title {
        font-size: 40px;
        text-align: center;
    }

    .contact-subtitle {
        font-size: 30px;
        line-height: normal;
        text-align: center;
    }

    .contact-text-div {
        text-align: center;
        margin-top: 1.5rem;
    }

    .contact-detail,
    .contact-detail-email {
        font-size: 20px;
    }

    .contact-image-div {
        display: none;
    }
    .contact-image-div > img {
        width: 200px;
        height: 200px;
        border: .2rem solid #6c63ff;
        border-radius: 50%;
        
        margin: 2rem;
    }
}
